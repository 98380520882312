<template>
    <div class="enterpriseResource">
        <!--入住企业-->
        <div class="join-et w-1400">
            <div class="et-h3 clearfix">
                <div class="fl">入驻企业</div>
                <div class="fr more">
                    <router-link to="/enterprise">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <div class="join-list">
                <el-row :gutter="30">
                    <el-col :span="8" v-for="item in enterPrisePageData" :key="item.id">
                        <router-link :to="`/enterprisedetail?id=${item.id}&oParams=${JSON.stringify(enterPrisepageParams)}`">
                            <div class="join-item clearfix">
                                <div class="fl join-pic">
                                    <el-image style="width:100%;height:100%" :src="baseUrl+item.photoUrls[0]"></el-image>
                                </div>
                                <div class="join-info fr">
                                    <h4 class="ellipsis">{{item.name}}</h4>
                                    <div class="join-address">
                                        <i class="el-icon-location"></i>
                                        {{item.address}}
                                    </div>
                                    <div class="join-intro" v-html="item.introduction">
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--企业土地-->
        <div class="land w-1400">
            <div class="et-h3 clearfix">
                <div class="fl">企业土地</div>
                <div class="fr more">
                    <router-link to="/enterpriseLandList">
                    更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <div class="land-main clearfix mt-20">
                
                <div class="dt-left fl">
                    <div class="dt-box" v-for="(item,k) in enterpriselandData" :key="k + 'hj'" :class="{act:k == isact}" @mouseenter="isact=k" @click="goDetail(item.id,'企业土地',enterpriselandParams)">
                        <div class="place-pic">
                            <el-image :src="baseUrl+item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                        </div>
                        <div class="dt-ceng">
                            <div class="dt-lr">{{item.title}}</div> 
                        </div>
                    </div>    
                </div>
            </div>
        </div>
        <!--需求合作-->
        <div class="cooperation w-1400">
            <div class="et-h3 clearfix">
                <div class="fl">需求合作</div>
                <!-- <div class="fr more">
                    <router-link to="/enterpriseNeedZJList">
                    更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div> -->
            </div>
            <div class="cooperation-main">
                <el-row :gutter="20" class="mt-20">
                    <el-col :span="6">
                        <div class="c-card bg-fe620d">
                            <h4>资金需求</h4>
                            <div class="c-mark">
                               <ul >
                                   <li 
                                v-for="item in FundNeeds"
                                class="fl classify-item"
                                :key="item"
                                :class="{isactive1:enterpriseneedzjParams.filter.needType==item}"
                                @click="enterpriseneedzjParams.filter.needType=item;getEnterpriseNeedzjList();">{{item}}</li>
                                </ul>
                            </div>
                            <div class="c-pic">
                                <el-image :src="require('../../assets/img/zijin.png')" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                            
                            <!-- <div class="issue-btn">
                                立即发布资金需求
                            </div> -->
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <!-- <div class="pri-list">
                            <el-carousel trigger="click" height="450px"  arrow="never">
                                <el-carousel-item v-for="item in enterpriseneedzjData" :key="item.id">
                                    <div class="pri-pic">
                                        <router-link :to="`/enterpriseNeedZJDetail?id=${item.id}`">
                                        <el-image :src="baseUrl+item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                        </router-link>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div> -->
                        <div class="zj_item" 
                            :class="{cact:item == priceParams.filter.secondInfoType }" 
                            v-for="item in priceList" 
                            :key="item+'xm'"
                            @click="priceParams.filter.secondInfoType = item;getEnterpriseNeedzjList()"
                            >
                            <div class="xz_text">{{item}}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="cp-list" v-if="enterpriseneedzjData.length>0">
                            <div class="cp-item ellipsis" v-for="item in enterpriseneedzjData" :key="item.id" :class="{isact:item === 1}">
                                <router-link :to="`/Detail?id=${item.id}&type=资金需求&oParams=${JSON.stringify(priceParams)}`">
                                <!-- <span style="color:#367bff">￥{{item.funds}}万</span> -->
                                <span style="margin-left:5px">{{item.title}}</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="cp-list" v-else>
                            <el-empty description="暂无相关信息"></el-empty>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-20">
                    <el-col :span="6">
                        <div class="c-card bg-7eba0d">
                            <h4>项目需求</h4>
                            <div class="c-mark">
                                <ul>
                                   <li 
                                v-for="item in ProjectNeeds"
                                class="fl classify-item"
                                :key="item"
                                :class="{isactive1:enterpriseneedxmParams.filter.needType==item}"
                                @click="enterpriseneedxmParams.filter.needType=item;getEnterpriseNeedxmList();">{{item}}</li>
                                </ul>
                            </div>
                            <div class="c-pic xm">
                                <el-image :src="require('../../assets/img/xiangmu.png')" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                            <!-- <div class="issue-btn">
                                立即发布项目需求
                            </div> -->
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <!-- <div class="pri-list">
                            <el-carousel trigger="click" height="450px"  arrow="never">
                                <el-carousel-item v-for="item in enterpriseneedxmData" :key="item.id">
                                    <div class="pri-pic">
                                        <router-link :to="`/enterpriseNeedXMDetail?id=${item.id}`">                                       
                                        <el-image :src="baseUrl+item.photoUrls[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                        </router-link>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div> -->
                        <div class="xz_item" 
                            :class="{aact:item == NeedParams.filter.secondInfoType }" 
                            :style="`background-image:url(${require(`../../../public/img/xnw/bs${k+1}.png`)})`"
                            v-for="item,k in businessList" 
                            :key="item+'xm'"
                            @click="NeedParams.filter.secondInfoType = item;getEnterpriseNeedxmList()"
                            >
                            <div class="xz_text">{{item}}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="cp-list" v-if="enterpriseneedxmData.length>0">
                            <div class="cp-item ellipsis" v-for="item in enterpriseneedxmData" :key="item.id + 'cp'" :class="{isact:item === 1}">
                                <router-link :to="`/Detail?id=${item.id}&type=招商项目&oParams=${JSON.stringify(NeedParams)}`"> 
                                <!-- <span>项目名称：{{item.projectName}}</span> -->
                                    <span>{{item.title}}</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="cp-list" v-else>
                            <el-empty description="暂无相关信息"></el-empty>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--企业直聘-->
        <div class="zhiping w-1400">
            <div class="et-h3 clearfix">
                <div class="fl">企业直聘</div>
                <div class="fr more">
                    <router-link to="/recruit">更多<i class="el-icon-arrow-right"></i></router-link>
                </div>
            </div>
            <div class="zp-main">
                <el-row :gutter="36">
                    <el-col :span="8" v-for="item in pageData" :key="item.id+'zp'">
                        <router-link :to="`/recruitdetail?id=${item.id}&type=1`">
                            <div class="zp-item clearfix">
                                <div class="fl zp-info" >
                                    <p>{{item.jobTitle}}</p>
                                    <p style="color:#fe620d">{{item.salary}}</p>
                                    <p>{{item.companyName}}</p>
                                </div>
                                <div class="fr zp-pic">
                                    <el-image :src="item.photoUrls && item.photoUrls.split(',')[0]" style="width:100%;height:100%" fit="cover"></el-image>
                                </div>
                            </div>
                        </router-link>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import {GetEnterpriseInfos,GetXnrecruitInfos,GetZSDTinfos} from '@/api/xiangningwang'
import{GetEnterpriseNeedzj,GetEnterpriseNeedxm,GetEnterpriseLand} from '@/api/ziyuanjishi'
import {regHtml} from '@/utils/reg'
export default {
    data(){
        return{
            FundNeeds:["投资","金融","资产处理","其他"],
            ProjectNeeds:["项目申报","项目实施","项目评估","项目推广"],
            businessList:['特色农产品加工','乡村产业','农业小镇','生态畜牧业','休闲观光农业','农贸市场和蔬菜基地建设','农业产业化','冷链物流','有机肥加工'],
            priceList:['不限','0-500 万','500-1000 万','1000-2000 万','2000-3000 万','3000-4000 万','4000-5000 万','5000-10000 万','10000 万以上'],
            transNum:0,
            calList:[{},{},{},{},{}],
            istrans:true,
            actimg:'',
            isact:1,
            enterPrisePageData:[],
            enterPrisepageParams:{currentPage:1,pageSize:6},
            enterpriselandData:[],
            enterpriselandParams:{
                currentPage:1,
                filter:{title:""},
                pageSize:4},
            enterpriseneedzjData:[],
            enterpriseneedzjParams:{
                currentPage:1,
                filter:{title:"",needType:""},
                pageSize:10
            },
             enterpriseneedxmData:[],
            enterpriseneedxmParams:{
                currentPage:1,
                filter:{title:"",needType:""},
                pageSize:10
            },
            params:{
                currentPage:1,
                // filter:{name:''},
                pageSize:6
            },
            pageData:{},
            NeedParams:{
                currentPage: 1,
                filter: { infoType: 257023606935621,secondInfoType:'特色农产品加工'},
                pageSize: 10,
            },
            priceParams:{
                currentPage: 1,
                filter: { infoType: 331379287793733,secondInfoType:'不限'},
                pageSize: 10,
            },
        }
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    methods:{
        next(){
            console.log(this.transNum,-this.calList.length*245)
            if(this.transNum === -this.calList.length*245/2){
                this.transNum = 0
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum -= 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum -= 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        prev(){
            if(this.transNum === 0){
                this.transNum = -this.calList.length*245/2
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum += 245
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum += 245
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        getEnterPrisePageList(){
            GetEnterpriseInfos(this.enterPrisepageParams).then(res=>{
                const data = res.data.data.list;
                data.forEach((d) => {
                    d.photoUrls = d.photoUrls.split(",");
                });
                this.enterPrisePageData = data;
            })
        },
        getEnterPriseLandList(){
            GetEnterpriseLand(this.enterpriselandParams).then(res=>{
                const  data=res.data.data.list;
                data.forEach(element => {
                    element.photoUrls = element.photoUrls.split(",");
                });
                this.enterpriselandData=data;
            })
        },
        goDetail(id,type,oParams){
            JSON.stringify
            this.$router.push({path:'/enterpriseLandDetail',query:{id,type,oParams:JSON.stringify(oParams)}})
        },
        // getEnterpriseNeedzjList(){
        //     GetEnterpriseNeedzj(this.enterpriseneedzjParams).then(res=>{
        //         const zjdata=res.data.data.list;
        //         zjdata.forEach(element=>{
        //             element.photoUrls=element.photoUrls.split(',');
        //         });
        //         this.enterpriseneedzjData=zjdata;
        //     })
        // },
        getEnterpriseNeedzjList(){
            const params = JSON.parse(JSON.stringify(this.priceParams))
            if(params.filter.secondInfoType == '不限') params.filter.secondInfoType = ''
            GetZSDTinfos(params).then(res=>{
                const zjdata=res.data.data.list;
                zjdata.forEach(element=>{
                    element.photoUrls=element.photoUrls.split(',');
                });
                this.enterpriseneedzjData=zjdata;
            })
        },
        // getEnterpriseNeedxmList(){
        //     GetEnterpriseNeedxm(this.enterpriseneedxmParams).then(res=>{
        //         const xmdata=res.data.data.list;
        //         xmdata.forEach(element=>{
        //             element.photoUrls=element.photoUrls.split(',');
        //         });
        //         this.enterpriseneedxmData=xmdata;
        //     })
        // },
        getEnterpriseNeedxmList(){
            GetZSDTinfos(this.NeedParams).then(res=>{
                const xmdata=res.data.data.list;
                xmdata.forEach(element=>{
                    element.photoUrls=element.photoUrls.split(',');
                });
                this.enterpriseneedxmData=xmdata;
            })
        },
        
         getPageList() {
            GetXnrecruitInfos(this.params).then((res) => {
                this.pageData = res.data.data.list;
            });
        },
    },
    mounted(){
        this.getEnterPrisePageList();
        this.calList =this.calList.concat(this.calList)//临时的 该用于获取数据后
        this.getEnterPriseLandList();
        this.getEnterpriseNeedzjList();
        this.getEnterpriseNeedxmList();
        this.getPageList();
    }
}
</script>
<style scoped>
.zj_item{
    position: relative;
    height: 42px;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: cover;
    transition: all .3s;
    cursor: pointer;
}
.zj_item.cact{
    height: 114px;
}
.zj_item.cact .xz_text{
    line-height: 100px;
    font-size: 16px;
    font-weight: bold;
}
.xz_item{
    position: relative;
    height: 36px;
    background-repeat: no-repeat;
    background-position:center center;
    background-size: cover;
    transition: all .3s;
    cursor: pointer;
}

.xz_item.aact{
    height: 126px;
}

.xz_item.aact .xz_text{
    line-height: 48px;
    font-size: 16px;
    font-weight: bold;
}
.xz_text{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    line-height: 36px;
    text-align: center;
    transition: all 0.3s;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);
    color: #fff;
}
.pri-list >>> .el-carousel__button{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    /* background: #fe620d */
}

.classify-item {
  /* padding: 0px 25px; */
  font-size: 16px;
  line-height: 36px;
  cursor: pointer;
  color: #fff;
  width: 40%;
}
.classify-item:nth-child(even){
    text-align: right;
}
.classify-item:nth-child(odd){
    text-align: left;
}
.isactive {
  color: #4283d2;
  cursor: pointer;
}
.dt-lr{
    margin: 0 auto;
    height: 100%;
    writing-mode: vertical-lr;
    writing-mode: tb-lr;
    font-size: 20px;
    color: #fff;
    padding-top:30px;
}
.act .dt-ceng{
    opacity: 0;
}
.dt-ceng{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(23,100,206,0.7);
    opacity: 1;
    transition: opacity 0.8s;
}
.dt-box.act{
    width: 960px;
}
.place-pic{
    width: 960px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%)
}
.dt-box{
    width: 80px;
    height: 500px;
    position: relative;
    overflow: hidden;
    transition: all 0.8s;
    cursor: pointer;
}
.dt-left{
    width: 1200px;
    display: flex;
}
.zp-info{
    width: calc( 100% - 145px);
    line-height: 30px;
    font-size: 17px;
}
.zp-pic{
    height: 90px;
    width: 122px;
}
.zp-item{
    padding: 20px;
    border: 1px solid #E4E7ED;
    margin-top: 20px;
    text-align: center;
}
.c-pic.xm{
    width: 220px;
    margin: 40px auto;
}
.cp-item.isact a,.cp-item:hover a{
    color: #0e69c2;
}
.cp-item{
    line-height: 41px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #eee;
}
.cp-list{
    height: 450px;
    border:1px solid #E4E7ED;
    padding:20px 20px;
}
.pri-pic{
    height: 450px;
}
.issue-btn:hover{
    background: rgba(255, 255, 255, .4);
    cursor: pointer;
}
.issue-btn{
    margin:32px auto;
    width: 80%;
    /* height: 36px; */
    line-height: 36px;
    border: 2px solid #fff;
    border-radius:3px;
}
.c-pic{
    /* width: 90%; */
    margin-top: 40px;
    height: 147px;
}
.c-mark{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    margin: 16px auto;
}
.c-mark ul{
    width: 100%;
    padding-left: 20px;
}
.c-card h4{
    font-size: 20px;
    line-height: 50px;
}
.c-card{
    font-size: 17px;
    color: #fff;
    text-align: center;
    padding: 20px;
    height: 450px;
    overflow: hidden;
}
.bg-fe620d{
    background: #fe620d;
}
.bg-7eba0d{
    background: #7eba0d;
}
.land-big-title{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 52px;
    font-size: 20px;
    line-height: 52px;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    text-align: center;
}
.land-big-pic{
    height: 100%;
}
.land-big{
    margin-top: 10px;
    position: relative;
    height: 720px;
    overflow: hidden;;
}
.isSlace{
    transform: scale(1.2);
    border: 2px solid #fff;
}
.istrans{
    transition: transform 1.2s;
}
.prev{
    left: 0;
}
.next{
    right: 0;
}
.cal-btn{
    width: 50px;
    height: 80px;
    line-height: 80px;
    font-size: 50px;
    font: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 220px;
    height: 132px;
    overflow: hidden;
    margin-right: 25px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.cal-small{
    height: 200px;
    padding-top:34px;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.join-intro{
    font-size: 16px;
    line-height: 21px;
    height: 48px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
}
.join-address i{
    color: #0e69c2;
}
.join-address{
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    color: #666;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
}
.join-info h4{
    line-height:30px;
    font-size:20px;
}
.join-info{
    width: calc(100% - 140px);
    height: 120px;
    overflow: hidden;
}
.join-pic{
    width: 120px;
    height: 120px;
    background: cyan;
}
.join-item{
    border: 1px solid #E4E7ED;
    padding: 20px;
    margin-top: 30px;
}
.et-h3{
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    border-bottom: 1px solid #E4E7ED;
}
.enterpriseResource{
    font-family: '微软雅黑';
}
</style>
